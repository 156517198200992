/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function($) {

    var websiteConfig = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
                var userFeed = new Instafeed({
                    target: 'instagram',
                    get: 'user',
                    userId: '30632352',
                    accessToken: '30632352.1efe6bf.8561b5663cda4c1bbe2483a46a529def'
                });
                userFeed.run();

                $('.open-search').on('click', function(e){
                    e.preventDefault();
                    $('nav.main').addClass('search-displayed');
                    $('.search-form').fadeIn();
                });

                $('.open-search-mobile').on('click', function(e){
                    e.preventDefault();

                    if ($('#main-menu').hasClass('open')){
                        $('#main-menu').slideUp(function(){
                            $(this).removeClass('open');
                            $('.close-search').addClass('mobile');
                            $('.search-form').show();
                        })
                    }else{
                        $('.close-search').addClass('mobile');
                        $('.search-form').show();
                    }
                });

                $('.close-search').on('click', function(e){
                    e.preventDefault();

                    if ($(this).hasClass('mobile')){
                        $('.search-form').hide();
                        $(this).removeClass('mobile');
                    }else {
                        $('.search-form').fadeOut(function () {
                            $('nav.main').removeClass('search-displayed');
                            $('#searchform').trigger("reset");
                        });
                    }
                });

            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = websiteConfig;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery);